import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';

import LoadingIndicator from './LoadingIndicator';
import SplashScreen from './SplashScreen';
import TermsConditions from './components/TermsConditions/TermsConditions';
import RefundPolicy from './components/RefundPolicy/RefundPolicy';
import { AuthProvider, useAuth } from './AuthContext';
import { initGA, logPageView } from './Analytics';
import RedirectingScreen from './components/RedirectingScreen';

const Header = lazy(() => import('./components/Header/Header'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const Home = lazy(() => import('./components/Home/Home'));
const DataPrivacy = lazy(() => import('./components/DataPrivacy/DataPrivacy'));
const BookNow = lazy(() => import('./components/BookNow/BookNow'));
const EventGallery = lazy(() => import('./components/EventGallery/EventGallery'));
const AccessoryEvents = lazy(() => import('./components/AccessoryEvents/AccessoryEvents'));
const ApparelEvents = lazy(() => import('./components/ApparelEvents/ApparelEvents'));
const AutomobileEvents = lazy(() => import('./components/AutomobileEvents/AutomobileEvents'));
const CorporateEvents = lazy(() => import('./components/CorporateEvents/CorporateEvents'));
const SocialEvents = lazy(() => import('./components/SocialEvents/SocialEvents'));
const AboutUs = lazy(() => import('./components/AboutUs/AboutUs'));
const SocialMedia = lazy(() => import('./components/SocialMedia/SocialMedia'));
const Experiences = lazy(() => import('./components/Experiences/Experiences'));
const Rooms = lazy(() => import('./components/Rooms/Rooms'));
const KitchenStories = lazy(() => import('./components/KitchenStories/KitchenStories'));
const Celebrations = lazy(() => import('./components/Celebrations/Celebrations'));
const HotCrusty = lazy(() => import('./components/HotCrusty/HotCrusty'));
const Gravity = lazy(() => import('./components/Gravity/Gravity'));
const Wedding = lazy(() => import('./components/SocialEvents/Wedding/Wedding'));
const Birthday = lazy(() => import('./components/SocialEvents/Birthday/Birthday'));
const More = lazy(() => import('./components/SocialEvents/More/More'));
const Error = lazy(() => import('./components/Error/Error'));
const Blogs = lazy(() => import('./components/Blogs/Blogs'));
const Blog1 = lazy(() => import('./components/Blogs/Blog1'));

const Login = lazy(() => import('./components/WebApp/Login/Login'));
const ForgotPassword = lazy(() => import('./components/WebApp/ForgotPassword/ForgotPassword'));
const Otp = lazy(() => import('./components/WebApp/Otp/Otp'));
const WebHeader = lazy(() => import('./components/WebApp/WebHeader/WebHeader'));
const Sidebar = lazy(() => import('./components/WebApp/Sidebar/Sidebar'));
const Dashboard = lazy(() => import('./components/WebApp/Dashboard/Dashboard'));
const ChangePassword = lazy(() => import('./components/WebApp/ChangePassword/ChangePassword'));
const StayPreferences = lazy(() => import('./components/WebApp/StayPreferences/StayPreferences'));
const BankCards = lazy(() => import('./components/WebApp/BankCards/BankCards'));
const IdentityDocuments = lazy(() => import('./components/WebApp/IdentityDocuments/IdentityDocuments'));
const UpdatePassword = lazy(() => import('./components/WebApp/UpdatePassword/UpdatePassword'));
const RewardPoints = lazy(() => import('./components/WebApp/RewardPoints/RewardPoints'));
const CompanyProfile = lazy(() => import('./components/WebApp/CompanyProfile/CompanyProfile'));
const MeetingsEvents = lazy(() => import('./components/WebApp/MeetingsEvents/MeetingsEvents'));
const BookRide = lazy(() => import('./components/WebApp/BookRide/BookRide'));
const Booking = lazy(() => import('./components/WebApp/Booking/Booking'));
const BookRoom = lazy(() => import('./components/WebApp/BookRoom/BookRoom'));
const SelectedRoom = lazy(() => import('./components/WebApp/SelectedRoom/SelectedRoom'));
const Checkout = lazy(() => import('./components/WebApp/Checkout/Checkout'));
const YourReservations = lazy(() => import('./components/WebApp/YourReservations/YourReservations'));
const AboutRewardPoints = lazy(() => import('./components/WebApp/AboutRewardPoints/AboutRewardPoints'));
const EarnPoints = lazy(() => import('./components/WebApp/EarnPoints/EarnPoints'));
const NeedHelp = lazy(() => import('./components/WebApp/NeedHelp/NeedHelp'));
const SuccessfulBooking = lazy(() => import('./components/WebApp/SuccessfulBooking/SuccessfulBooking'));
const UnsuccessfulBooking = lazy(() => import('./components/WebApp/UnsuccessfulBooking/UnsuccessfulBooking'));
const Download = lazy(() => import('./components/WebApp/Download/Download'));
const PrivacySecurity = lazy(() => import('./components/WebApp/PrivacySecurity/PrivacySecurity'));
const HelpSupport = lazy(() => import('./components/WebApp/HelpSupport/HelpSupport'));
const AboutStatus = lazy(() => import('./components/WebApp/AboutStatus/AboutStatus'));

const MyCalendar = lazy(() => import('./components/WebApp/MyCalendar/MyCalendar'));

function SplashScreenRoute() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1700);

    return () => clearTimeout(timer);
  }, []);

  const showSplashScreen = location.pathname === '/';

  return isLoading && showSplashScreen ? <SplashScreen /> : null;
}

function ProtectedRoute({ element }) {
  const { user } = useAuth();
  return user ? element : <Navigate to="/Login" />;
}
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    initGA('G-59PJD8B14W'); // Replace with your Measurement ID
    logPageView();
  }, []);
  return (
    <Router>
      <RouteChangeTracker />
      <AuthProvider>
        <Suspense fallback={<LoadingIndicator />}>
          <SplashScreenRoute />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/Header" element={<Header />} />
            <Route path="/Footer" element={<Footer />} />
            <Route path="/DataPrivacy" element={<DataPrivacy />} />
            <Route path="/TermsConditions" element={<TermsConditions />} />
            <Route path="/RefundPolicy" element={<RefundPolicy />} />
            <Route path="/BookNow" element={<BookNow />} />
            <Route path="/EventGallery" element={<EventGallery />} />
            <Route path="/AccessoryEvents" element={<AccessoryEvents />} />
            <Route path="/ApparelEvents" element={<ApparelEvents />} />
            <Route path="/AutomobileEvents" element={<AutomobileEvents />} />
            <Route path="/CorporateEvents" element={<CorporateEvents />} />
            <Route path="/SocialEvents" element={<SocialEvents />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/Experiences" element={<Experiences />} />
            <Route path="/Rooms" element={<Rooms />} />
            <Route path="/KitchenStories" element={<KitchenStories />} />
            <Route path="/Celebrations" element={<Celebrations />} />
            <Route path="/HotCrusty" element={<HotCrusty />} />
            <Route path="/Gravity" element={<Gravity />} />
            <Route path="/SocialMedia" element={<SocialMedia />} />
            <Route path="/Wedding" element={<Wedding />} />
            <Route path="/Birthday" element={<Birthday />} />
            <Route path="/More" element={<More />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/Otp" element={<Otp />} />
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/RedirectingScreen" element={<RedirectingScreen />} />
            <Route path="/Blog1" element={<Blog1 />} />

            {/* Protected Routes */}
            <Route path="/WebHeader" element={<ProtectedRoute element={<WebHeader />} />} />
            <Route path="/Sidebar" element={<ProtectedRoute element={<Sidebar />} />} />
            <Route path="/Dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
            <Route path="/ChangePassword" element={<ProtectedRoute element={<ChangePassword />} />} />
            <Route path="/StayPreferences" element={<ProtectedRoute element={<StayPreferences />} />} />
            <Route path="/BankCards" element={<ProtectedRoute element={<BankCards />} />} />
            <Route path="/IdentityDocuments" element={<ProtectedRoute element={<IdentityDocuments />} />} />
            <Route path="/UpdatePassword" element={<ProtectedRoute element={<UpdatePassword />} />} />
            <Route path="/RewardPoints" element={<ProtectedRoute element={<RewardPoints />} />} />
            <Route path="/CompanyProfile" element={<ProtectedRoute element={<CompanyProfile />} />} />
            <Route path="/MeetingsEvents" element={<ProtectedRoute element={<MeetingsEvents />} />} />
            <Route path="/BookRide" element={<ProtectedRoute element={<BookRide />} />} />
            <Route path="/Booking" element={<ProtectedRoute element={<Booking />} />} />
            <Route path="/BookRoom" element={<ProtectedRoute element={<BookRoom />} />} />
            <Route path="/SelectedRoom" element={<ProtectedRoute element={<SelectedRoom />} />} />
            <Route path="/Checkout" element={<ProtectedRoute element={<Checkout />} />} />
            <Route path="/YourReservations" element={<ProtectedRoute element={<YourReservations />} />} />
            <Route path="/AboutRewardPoints" element={<ProtectedRoute element={<AboutRewardPoints />} />} />
            <Route path="/EarnPoints" element={<ProtectedRoute element={<EarnPoints />} />} />
            <Route path="/NeedHelp" element={<ProtectedRoute element={<NeedHelp />} />} />
            <Route path="/SuccessfulBooking" element={<ProtectedRoute element={<SuccessfulBooking />} />} />
            <Route path="/UnsuccessfulBooking" element={<ProtectedRoute element={<UnsuccessfulBooking />} />} />
            <Route path="/Download" element={<ProtectedRoute element={<Download />} />} />
            <Route path="/PrivacySecurity" element={<ProtectedRoute element={<PrivacySecurity />} />} />
            <Route path="/HelpSupport" element={<ProtectedRoute element={<HelpSupport />} />} />
            <Route path="/AboutStatus" element={<ProtectedRoute element={<AboutStatus />} />} />

            <Route path="/MyCalendar" element={<ProtectedRoute element={<MyCalendar />} />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Suspense>
      </AuthProvider>
    </Router>
  );
}

export default App;
