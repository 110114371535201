import React, { useEffect } from 'react';

const RedirectingScreen = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://admin.rpjhotels.com/';
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="text-center">
        <div className="flex items-center justify-center mb-4">
          <div className="relative">
            <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-6 h-6 border-t-4 border-yellow-500 border-solid rounded-full animate-spin"></div>
            </div>
          </div>
        </div>
        <h2 className="text-2xl font-semibold text-gray-800">Please wait, we are redirecting you to admin panel...</h2>
      </div>
    </div>
  );
};

export default RedirectingScreen;
