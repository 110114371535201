import React from 'react';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';

const schema = {
  "@context": "http://schema.org",
  "@type": "Hotel",
  "name": "RPJ Hotel",
  "url": "https://www.rpjhotels.com",
  "description": "Experience luxury and comfort at the best corporate hotel in Rajkot. Book now and enjoy premium amenities for your business trip.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Kalavad Rd, Near Everest Park, Jai Bhimnagar, Nana Mava",
    "addressLocality": "Rajkot",
    "addressRegion": "Gujarat",
    "postalCode": "360005",
    "addressCountry": "India"
  },
  "image": "https://rpjhotels.com/assets/rpj_hotel_logo.webp"
}



const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
    <App />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
