import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const TermsConditions = () => {
    return (
    <div>
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
        <Header/>

            <div>
                <img className='md:hidden' src="../assets/datambanner.webp" alt="Header" />
                <img className='hidden md:block' src="../assets/DataWallpaper.webp" alt="Header" />
                <p className='text-[12px] md:text-sm 2xl:text-lg leading-4 text-white text-center font-semibold -mt-20 md:-mt-20 lg:-mt-20 2xl:-mt-28' 
                    style={{ letterSpacing: '0.5px', wordSpacing: '1px' }}
                >
                    SECURITY
                </p>
                <p className='heading text-white text-center lg:text-xl 2xl:text-4xl mt-4'>Terms & Conditions</p>
            </div>

            <div className='mt-14 md:mt-16 lg:mt-20 2xl:mt-40 px-4 md:px-10 lg:px-14 2xl:px-32'>        
                <p className='title text-lg md:text-2xl 2xl:text-4xl'>Terms & Conditions</p>
                <div className='mt-5'>
                    <p>
                    This website is owned and operated by RPJ Hotel Rajkot. By using the Website and making bookings, you agree to these Terms and Conditions (“T&Cs”). These Terms and Conditions govern your stay at the Hotel for accommodation, as well as the use of hotel facilities and related services rendered by the Hotel to the guests. The Hotel may however request the guest to sign a separate disclaimer form or other declaration for the specific service or activity you wish to use or join, and may have additional terms and conditions. Please read them carefully.
                    </p>
                </div>

                <div className='font-bold mt-5'>
                Accommodation
                </div>

                <div>
                    <div className='mt-3 ml-2'>
                        <p>
                        (a) We reserve our right to enter a guest room for any purpose, and in particular for the safety and security of guests and our property.
                        </p>
                        <p>
                        (b) Guests are advised to place their valuables in our safe deposit boxes located at the Front Desk. The Hotel assumes no responsibility for any loss of money, jewels or other valuables not stored in our safe deposit boxes located at our Front Desk. In particular, but without limitation, we are not responsible for any contents left in the guest room. 
                        </p>
                        <p>
                        (c) If your company or agent fails to settle any payment within the specified period or within a reasonable period, you will be personally (and you and your company or agent will be jointly and severally) liable for the payment of your account..
                        </p>
                    </div>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Booking Process</p>
                    <p>
                    •⁠  ⁠Eligibility: You must be at least 18 years old to book on this website.
                    </p>
                    <p>
                    •⁠  ⁠Information: You agree to provide accurate and complete information for booking.

                    </p>
                    <p>
                    •⁠  ⁠Confirmation: Your booking is confirmed upon successful payment and a confirmation email from the Hotel.

                    </p>
                    <p>
                    •⁠  ⁠Modifications: Changes to your booking may incur fees. Contact the Hotel directly for modification requests.

                    </p>
                </div>
                
                <div>
                    <p className='font-semibold mt-5'>Pricing and Payment</p>
                    <p>
                    •⁠  ⁠Currency: All prices are in INR by default. Change the currency as per your need.
                    </p>
                    <p>
                    •⁠  ⁠Taxes/Fees: Prices DO NOT include taxes. Additional fees may apply (e.g., resort fees). See booking details for total price.
                    </p>
                    <p>
                    •⁠  ⁠Payment Methods: We accept [list payment methods].
                    </p>
                    <p>
                    •⁠  ⁠Deposits: A [percentage] deposit is required, refundable according to cancellation policy.
                    </p>
                </div>

                <div className='font-semibold mt-5'>Cancellation Policy</div>
                <div className='ml-3'>
                    <p>
                    •⁠  ⁠Standard: Cancellations made 7 days before arrival receive a full refund of the deposit. Cancellations within 3 days incur a penalty of 10%. Cancellations within 1 days, and no-shows, forfeit the deposit.
                    </p>
                    <p>
                    •⁠  ⁠Non-refundable Rates: Bookings made under non-refundable rates are not eligible for refunds.
                    </p>
                    <p>
                    •⁠  ⁠Force Majeure: If you or the Hotel cannot fulfill obligations due to events beyond reasonable control (e.g., natural disasters), contact the Hotel to discuss potential rebooking or refunds.
                    </p>
                </div>

                <div className='mt-5'>
                    <p className='font-semibold'>Check-in/Check-out</p>
                    <p>
                    •⁠  ⁠Check-in: 12:00 PM
                    </p>
                    <p>
                    •⁠  ⁠Check-out: 10:00 AM
                    </p>
                    <p>
                    •⁠  ⁠Early/Late: May incur additional fees
                    </p>
                    <p>
                    •⁠  ⁠Identification: Valid government-issued ID is required for all guests.
                    </p>
                </div>

                <div className='mt-5'>
                    <p className='font-semibold'>Guest Conduct</p>
                    <p>
                    •⁠  ⁠Respect: Treat the Hotel and its staff with respect.
                    </p>
                    <p>
                    •⁠  ⁠Noise: Observe quiet hours after 10:00 PM.
                    </p>
                    <p>
                    •⁠  ⁠Damages: You are liable for damages you or your guests cause.
                    </p>
                    <p>
                    •⁠  ⁠Smoking: Smoking is not permitted in the rooms or hotel lobby.
                    </p>
                    <p>
                    •⁠  ⁠Guest Limits: Maximum of 3 guests per room.
                    </p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Hotel's Rights</p>
                    <p>
                    •⁠  ⁠Refusal: The Hotel may refuse service if you are intoxicated, disorderly, or violate T&Cs.
                    </p>
                    <p>
                    •⁠  ⁠Overbooking: In rare cases, the Hotel will help you locate a comparable hotel.
                    </p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Liability</p>
                    <p>
                    •⁠  ⁠Limitation: The Hotel is not liable for loss or damage beyond its reasonable control.
                    </p>
                    <p>
                    •⁠  ⁠Lost Property: The Hotel is not responsible for lost items, but will make reasonable efforts to assist.
                    </p>
                </div>
                    
                <div>
                    <p className='font-semibold mt-5'>Privacy</p>
                        <p>
                        •⁠  ⁠We collect: Name, Address, DOB 
                        </p>
                        <p>
                        •⁠  ⁠Compliance: See our Privacy Policy for details.
                        </p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Disclaimers</p>
                        <p>
                        •⁠  ⁠Accuracy: The Website aims for accuracy, but cannot guarantee it.
                        </p>
                        <p>
                        •⁠  ⁠Third-Party Links: The Hotel is not liable for content on third-party websites.
                        </p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>⁠ ⁠Governing Law</p>
                        <p>
                        •⁠  ⁠Governing Law: These T&Cs are governed by the laws of India
                        </p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Changes to T&Cs The Hotel may update these T&Cs. You will be notified of changes.</p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Contact For questions about these T&Cs</p>
                    <p>contact Email: info@rpjhotels.com</p>
                    <p>contact Phone: +91 7043877777</p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Acceptance</p>
                        <p>By using this Website, you agree to these T&Cs.</p>
                </div>

                <div>
                    <p className='font-semibold mt-5'>Important</p>
                        <p>
                        This is a starting point. Legal consultation is essential due to variations in local laws and your specific hotel operations.
                        </p>
                </div>

                <br/>
            </div>

           {/* Whatsapp icon */}
           <div className='fixed bottom-20 right-3 z-50'>
                <a href="https://www.instagram.com/rpjhotelrajkot?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                    target="_blank" rel="noopener noreferrer">
                    <img src='./assets/instagramicon.webp'
                        alt='Instagram-Icon'
                        className='rounded-full hover:scale-110 h-12 w-12 md:h-14 md:w-14 2xl:h-16 2xl:w-16 p-0.5' />
                </a>
            </div>

            <div className='fixed bottom-3 right-3 z-50'>
                <a href="https://api.whatsapp.com/send?phone=+917043877777&text=Hi RPJ Hotel team! I'm planning a trip to Rajkot soon and heard great things about your hotel. Could you please share room availability and rates for date rates? Also, any special offers? Thanks!"
                    target="_blank" rel="noopener noreferrer">
                    <img src='./assets/whatsapp_icon.webp'
                        alt='WhatsApp-Icon'
                        className='rounded-full hover:scale-110 h-12 w-12 md:h-14 md:w-14 2xl:h-16 2xl:w-16 p-0.5' />
                </a>
            </div>
            <Footer/>
        </div>
    </div>
    );
};

export default TermsConditions;