import React from 'react';
import './SplashScreen.css'

const SplashScreen = () => {
  return (
    <div className="Splash">
      <img className='logo' src="./assets/RPJLogoWhiteBG.gif" alt="Banner"/>
    </div>
  );
};

export default SplashScreen;
