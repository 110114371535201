import React from 'react';

const LoadingIndicator = () => {
  return (
    <div className="Splash">
    <img className='logo' src="./assets/RPJLogoWhiteBG.gif" alt="Banner"/>
  </div>
  );
};

export default LoadingIndicator;